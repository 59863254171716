jQuery(document).ready(function ($) {

    $globalSparePartsStatusOk = true; // global var for spare part section status; true until not checked
    $globalServiceRequestFormOk = true; // global var for service section status: true since not mandatory, set to false when service is checked

    $('.spare-parts-select-wrapper').fadeOut(); // dunno why but it screws spareparts select2 selector...

    function addSparePartPic(opt) {
        if (!opt.id) {
            return opt.text;
        }
        var optimage = $(opt.element).data('image');
        if (!optimage) {
            return opt.text;
        } else {
            var $opt = $(
                    '<span class="sparePartItem"><img src="' + optimage + '" class="sparePartPic" /> ' + $(opt.element).text() + '</span>'
                    );
            return $opt;
        }
    }

    /**
     * Select2 on call-for-action page
     */
    $("select[name='machine_ids[]']").select2({
        maximumSelectionLength: 1,
        language: {
            maximumSelected: function (e) {
                var t = "Puoi selezionare solo una macchina alla volta";
                e.maximum != 1 && (t += "s");
                return t;
            }
        },
        placeholder: 'Seleziona la macchina sulle quali richiedere l\'intervento',
        allowClear: true
    }).on('select2:select', function (e) {
        $('#newMachineCode').val('');
        $('#newMachineCode').attr('disabled', 'disabled');
        // $('.request-type-wrapper').fadeIn();
        $('.spare-parts-select-wrapper').fadeOut();
        $('.spare-parts-list-wrapper').fadeOut();
    });

    $("select[name='machine_ids[]']").on('select2:unselect', function (e) {
        $('#newMachineCode').val('');
        $('#newMachineCode').removeAttr('disabled');
        $("select[name='machine_ids[]']").removeAttr('disabled');
        if ($(this).val().length == 0) {
            // $('.request-type-wrapper').fadeOut();
            // $('.spare-parts-select-wrapper').fadeOut();
            $('.spare-parts-list-wrapper').fadeOut();
            $('input#spareparts').prop('checked', false);
            $('input#request_type_service').prop('checked', false);
            $('.service-form').fadeOut();
            $('.buttons-form').fadeOut();
        }
    });

    // If newMachineCode is selected unselect every other existing machine
    $('input[name="newMachineCode"]').on('change keyup blur', function () {
        if ($('input[name="newMachineCode"]').val().length != 0) {
            $("select[name='machine_ids[]']").val('').trigger("change");
            $("select[name='machine_ids[]']").attr('disabled', 'disabled');
            $('.request-type-wrapper').fadeIn();
        } else {
            $("select[name='machine_ids[]']").removeAttr('disabled');
            // $('.request-type-wrapper').fadeOut();
            // $('.spare-parts-select-wrapper').fadeOut();
            $('.spare-parts-list-wrapper').fadeOut();
            $('input#spareparts').prop('checked', false);
            $('input#request_type_service').prop('checked', false);
            $('.service-form').fadeOut();
            $('.buttons-form').fadeOut();
        }
    });

    $('#sparePartsSelect').select2({
        placeholder: 'Seleziona i ricambi desiderati',
        allowClear: true,
        templateResult: addSparePartPic,
        templateSelection: addSparePartPic
    });
    $('#sparePartsSelectContainer').hide();
    $('#newSparePartsCodes').change(function () {
        var newSparePartsString = $(this).val();
    });

    /**
     * Toggle spare part selection on request
     */
    $("#spareparts").on('change', function (e) {
        if ($(this).is(':checked')) {
            // $('.spare-parts-select-wrapper').fadeIn();
            $('.spare-parts-list-wrapper').fadeIn();
            $('#sparePartsListContainer').fadeIn();
            $('#sparePartsSelectContainer').fadeIn();
            $('.buttons-form').fadeOut();
            $globalSparePartsStatusOk = false;
        } else {
            // $('.spare-parts-select-wrapper').fadeOut();
            $('.spare-parts-list-wrapper').fadeOut();
            $('#sparePartsListContainer').fadeOut();
            $('#sparePartsSelectContainer').fadeOut();
            $('#sparePartsSelect').val(null).trigger("change");
            $('input.sp_cfa.qty').each(function () {
                $(this).val('0');
            });
            $('#totalPrice').val('');
            $('.buttons-form').fadeIn();
            $globalSparePartsStatusOk = true;
        }
    });

    /** If request is selected change label text to mandatory **/
    $('#request_type_service').on('change keyup blur', function (e) {
        if ($(this).is(':checked')) {
            $('label[for=subject]').text('Oggetto richiesta (obbligatorio)');
            $('label[for=body]').text('Dettaglio richiesta (obbligatorio)');
            $('.service-form').fadeIn();
            if ($('input[name=subject]').val() != "" && $('textarea[name=body]').val() != "") {
                $('.buttons-form').fadeIn();
            } else {
                $('.buttons-form').fadeOut();
            }
            $globalServiceRequestFormOk = false;
        } else {
            $('label[for=subject]').text('Oggetto richiesta (opzionale)');
            $('label[for=body]').text('Dettaglio richiesta (opzionale)');
            $('.service-form').fadeOut();
            $('.buttons-form').fadeOut();
            $globalServiceRequestFormOk = true;
        }
    });

    /** If no request type is selected, prevent form submit **/
    $('.request_type').on('change keyup blur', function (e) {
        if ($('input#spareparts').prop('checked') == false && $('input#request_type_service').prop('checked') == false)
            $('.buttons-form').fadeOut();
    });

    $('input[name=subject],textarea[name=body]').on('change keyup blur', function () {

        if ($('input[name=subject]').val() != "" && $('textarea[name=body]').val() != "") {
            $globalServiceRequestFormOk = true;
            if ($globalSparePartsStatusOk)
                $('.buttons-form').fadeIn();
        } else {
            $globalServiceRequestFormOk = false;
            $('.buttons-form').fadeOut();
        }
    });
    $('#sparePartsSelect').on('select2:select', function (e) {
        var data = e.params.data;
        $('.spare_parts_list li#' + data.id).show();
        $('.spare_parts_list li#' + data.id).addClass('selectedSparePart');
        $('.buttons-form').fadeOut();
    });

    $('#sparePartsSelect').on('select2:unselect', function (e) {
        var data = e.params.data;
        $('.spare_parts_list li#' + data.id).hide();
        // remove class on unselected existing spare parts
        $('.spare_parts_list li#' + data.id).removeClass('selectedSparePart');
        $('.buttons-form').fadeOut();
    });

    /** Remove spare part **/
    $('ul.spare_parts_list').on('click', '.sp_minus_icon', function () {
        $(this).parent("li.newSparePartCls").remove();
        $(this).parent("li.existingSparePart").hide();
        var clickedExistingSparePartId = $(this).parent("li").data('id');

        // remove class on unselected existing spare parts
        $('.spare_parts_list li#' + clickedExistingSparePartId).removeClass('selectedSparePart');
        $("#sparePartsSelect option[value=\"" + clickedExistingSparePartId + "\"]").prop("selected", false).parent().trigger("change.select2");

        // if no spare part is selected cannot submit form
        if ($('#sparePartsSelect').val() == '') {
            $('.buttons-form').fadeOut();
        } else {
            $('.buttons-form').fadeIn();
        }
    });

    /** Show/hide submit/clear buttons depending on existing spare parts fields status **/
    $(document).on('change keyup blur', '.ex_sp_comcod, .ex_sp_desc, .ex_sp_qty', function () {

        $allNewSparePartsFieldCompiled = true;
        $allExistingSparePartsFieldCompiled = false;
        $('.existingSparePartCls.selectedSparePart .ex_sp_comcod').each(function () {
            if ($(this).val() != '') {
                $allExistingSparePartsFieldCompiled = true;
            } else {
                $allExistingSparePartsFieldCompiled = false;
            }
        });

        $('.existingSparePartCls.selectedSparePart .ex_sp_desc').each(function () {
            if ($(this).val() != '') {
                $allExistingSparePartsFieldCompiled = true;
            } else {
                $allExistingSparePartsFieldCompiled = false;
            }
        });

        $('.existingSparePartCls.selectedSparePart .ex_sp_qty').each(function () {
//            console.log('changed existing spare part qty');
//            console.log($(this).val());
//            console.log(parseInt($(this).val()) > 0);
            if (parseInt($(this).val()) > 0) {
                $allExistingSparePartsFieldCompiled = true;
                $('.buttons-form').fadeIn();
            } else {
                $allExistingSparePartsFieldCompiled = false;
                $('.buttons-form').fadeOut();
            }
        });

        $('.newSparePartCls .new_sp_qty').each(function () {
            if (parseInt($(this).val()) > 0) {
                $allNewSparePartsFieldCompiled = true;
            } else {
                $allNewSparePartsFieldCompiled = false;
            }
        });

        if ($allExistingSparePartsFieldCompiled && $allNewSparePartsFieldCompiled) {
            $globalSparePartsStatusOk = true;
            $('.buttons-form').fadeIn();
        } else {
            $globalSparePartsStatusOk = false;
            $('.buttons-form').fadeOut();
        }
    });

    /** Show/hide submit/clear buttons depending on new spare parts fields status **/
    $(document).on('change keyup blur', '.new_sp_comcod, .new_sp_desc, .new_sp_qty', function () {
//        console.log('fired');
        $allNewSparePartsFieldCompiled = false;
        $allExistingSparePartsFieldsCompiled = true;

        $('.newSparePartCls .new_sp_comcod').each(function () {
            if ($(this).val() != '') {
                $allNewSparePartsFieldCompiled = true;
            } else {
                $allNewSparePartsFieldCompiled = false;
            }
        });

        $('.newSparePartCls .new_sp_desc').each(function () {
            if ($(this).val() != '') {
                $allNewSparePartsFieldCompiled = true;
            } else {
                $allNewSparePartsFieldCompiled = false;
            }
        });

        $('.newSparePartCls .new_sp_qty').each(function () {
            if (parseInt($(this).val()) > 0) {
                $allNewSparePartsFieldCompiled = true;
            } else {
                $allNewSparePartsFieldCompiled = false;
            }
        });

        // check if there are existing spare parts with no quantity set
        $('.existingSparePartCls.selectedSparePart .ex_sp_qty').each(function () {

            if (parseInt($(this).val()) > 0) {
                $allExistingSparePartsFieldsCompiled = true;
            } else {
                $allExistingSparePartsFieldsCompiled = false;
                $('.buttons-form').fadeOut();
            }
        });

        if ($allNewSparePartsFieldCompiled && $allExistingSparePartsFieldsCompiled) {
            $globalSparePartsStatusOk = true;
            $('.buttons-form').fadeIn();
        } else {
            $globalSparePartsStatusOk = false;
            $('.buttons-form').fadeOut();
        }
    });

    /** Create new spare part list entry **/
    $('#addSparePartBtn').on('click', function () {
        var newSparePartName = $('.newSparePartCls').size();
        $('.spare_parts_list').append(`<li class="newSparePartCls" style="display: list-item;">
                        <span class="sp_minus_icon"><i class="fi-minus"></i></span>
                        <input class="sp_cfa new_sp_comcod" type="text" name="newSpareParts_${newSparePartName}_commercial_code" value="" placeholder="Inserisci qui il CODICE del ricambio" />
                        <input class="sp_cfa new_sp_desc" type="text" name="newSpareParts_${newSparePartName}_name" value="" placeholder="Inserisci qui il DESCRITTIVO del ricambio" />
                        <input class="sp_cfa qty new_sp_qty" name="newSpareParts_${newSparePartName}_quantity" type="number" min="0"  step="1" value="0" />
                        <input class="sp_cfa price" type="text" name="sp_price_${newSparePartName}" value="0" readonly />
                    </li>`);
        $('.buttons-form').fadeOut();
    });

    /** Parse new spare part data before submit **/
    $('form[name="send-request-form"]').on('submit', function (e) {
        e.preventDefault();
//        debugger;
        // manage existing spare parts
        existingSparePartsArray = new Array();

        $('.selectedSparePart').each(function (index, element) {

            var indexEl = element.id;

            existingSparePartsArray[indexEl] = {};
            existingSparePartsArray[indexEl]["id"] = indexEl;
            existingSparePartsArray[indexEl]["commercial_code"] = $('input[name=existingSpareParts_' + indexEl + '_commercial_code').val();
            existingSparePartsArray[indexEl]["name"] = $('input[name=existingSpareParts_' + indexEl + '_name').val();
            existingSparePartsArray[indexEl]["quantity"] = $('input[name=existingSpareParts_' + indexEl + '_quantity').val();
            existingSparePartsArray[indexEl]["price"] = $('input[name=existingSpareParts_' + indexEl + '_price').val();
        });
        existingSparePartsArray = existingSparePartsArray.filter(function (el) {
            return el != null;
        });
        $('textarea[name=existingSpareParts]').val(JSON.stringify(existingSparePartsArray));

        // manage new spare parts
        newSparePartsArray = new Array();
        $('.newSparePartCls').each(function (index, element) {
            newSparePartsArray[index] = {};
            newSparePartsArray[index]["id"] = index;
            newSparePartsArray[index]["commercial_code"] = $('input[name=newSpareParts_' + index + '_commercial_code').val();
            newSparePartsArray[index]["name"] = $('input[name=newSpareParts_' + index + '_name').val();
            newSparePartsArray[index]["quantity"] = $('input[name=newSpareParts_' + index + '_quantity').val();
        });
        newSparePartsArray = newSparePartsArray.filter(function (el) {
            return el != null;
        });
        $('textarea[name=newSpareParts]').val(JSON.stringify(newSparePartsArray));
        $(this).submit();
        return true;
    });

    /*
     * Change total spare parts cost on CFA
     */
    $('input.ex_sp_qty.qty').on('change', function () { // on every quantity change
//        console.log('fired');
        totalValue = 0;
        $('li.selectedSparePart input.ex_sp_qty.qty').each(function () { // calculate subtotal for every item selected
            if (!isNaN($(this).data('amount'))) { // exclude priceless items
//                console.log("val*amount", $(this).val() * $(this).data('amount'));
                totalValue += $(this).val() * $(this).data('amount');
                $('#totalPrice').val(totalValue); // set total
            }
        });
    });

    $('.reset-btn').on('click', function () {
        $('form[name="send-request-form"]')[0].reset();
        // $('.request-type-wrapper').fadeOut();
        $('input#spareparts').prop('checked', false);
        $('input#request_type_service').prop('checked', false);
        $('.service-form').fadeOut();
        $('.buttons-form').fadeOut();
        $('.spare-parts-select-wrapper').fadeOut();
        $('.spare-parts-list-wrapper').fadeOut();
        $('#sparePartsListContainer').fadeOut();
        $('#sparePartsSelectContainer').fadeOut();
        $('#machine_ids').val(null).trigger("change");
        $('#sparePartsSelect').val(null).trigger("change");
        $('input.sp_cfa.qty').each(function () {
            $(this).val('0');
        });
    });
});
